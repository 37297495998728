// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-[slug]-tsx": () => import("./../../../src/pages/blog/[slug].tsx" /* webpackChunkName: "component---src-pages-blog-[slug]-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-categories-js": () => import("./../../../src/pages/categories.js" /* webpackChunkName: "component---src-pages-categories-js" */),
  "component---src-pages-cennik-tsx": () => import("./../../../src/pages/cennik.tsx" /* webpackChunkName: "component---src-pages-cennik-tsx" */),
  "component---src-pages-clients-js": () => import("./../../../src/pages/clients.js" /* webpackChunkName: "component---src-pages-clients-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-czapkinatki-js": () => import("./../../../src/pages/czapkinatki.js" /* webpackChunkName: "component---src-pages-czapkinatki-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kategoria-[title]-tsx": () => import("./../../../src/pages/kategoria/[title].tsx" /* webpackChunkName: "component---src-pages-kategoria-[title]-tsx" */),
  "component---src-pages-opinions-js": () => import("./../../../src/pages/opinions.js" /* webpackChunkName: "component---src-pages-opinions-js" */)
}

