import React from "react";
import Layout from "../components/layout";

import { Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import "./contact.scss";
import loadable from "@loadable/component";
import { isMobile } from "react-device-detect";
import { Formik } from "formik";
import * as emailjs from "@emailjs/browser";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const mrkasd = "iRsuNNH6rHQYWQFNs";

const AnimatedCursor = loadable(() => import("../components/AnimatedCursor"));

const Contact = ({ isComponent }) => {
  const onSubmit = async (values) => {
    console.log(values);
    try {
      const res = await emailjs.send(
        "service_cjz71ws",
        "template_ea51joy",
        {
          from_name: values.name,
          email: values.email,
          message: values.text,
        },
        mrkasd,
      );
      if (res.status === 200) {
        toast.success("Wiadomość wysłana pomyślnie");
      }
    } catch (err) {
      toast.error("Ups.. coś poszło nie tak");
    }
  };

  const res = (
    <>
      <Row className='w-100'
      >
        <Col sm="6">
          <div className="contact_information">
            <img height={100} src="/logoWhite.png" />
            <br />
            tel: 508070761 <br />
            email: fotobello2019@gmail.com <br />
            <p style={{ textAlign: "center" }}>
              Ul. Piaskowa 45, 43-200 Pszczyna
            </p>
            <img
              style={{ width: 400, height: 200, objectFit: "contain" }}
              src="/images/house.jpeg"
            />
          </div>
        </Col>
        {!isComponent && (
          <Col sm="2">
            <img
              className="mx-4"
              width={300}
              src="/images/https___fotobello.com.pl_contact_.jpg"
            />
          </Col>
        )}
        <Col sm="6">
          <Formik
            initialValues={{ name: "", email: "", text: "" }}
            onSubmit={onSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <Form id="contact-formik" onSubmit={handleSubmit}>
                <FormGroup>
                  <Label>Imię</Label>
                  <Input
                    type="name"
                    name="name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Twój Email</Label>
                  <Input
                    type="email"
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Treść</Label>
                  <Input
                    type="textarea"
                    name="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.text}
                  />
                </FormGroup>
                <button type="submit">Wyślij</button>
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
    </>
  );

  if (isComponent) {
    return <>{res}</>;
  }

  return (
    <Layout>
      <ToastContainer />
      {!isMobile && <AnimatedCursor />}
      <Row
        style={{ padding: "50px 0" }}
        id="contact"
        className="align-items-center flex-row"
      >
        <Col sm="4">
          <div className="contact_information">
            <img height={100} src="/logoWhite.png" />
            <br />
            tel: 508070761 <br />
            email: fotobello2019@gmail.com <br />
            <p style={{textAlign:'center'}}>
              Ul. Piaskowa 45, 43-200 Pszczyna
            </p>
            <img style={{width:400,height:200,objectFit:'contain'}} src="/images/house.jpeg"/>
          </div>

        </Col>
        <Col sm="2">
          <img className="mx-4" width={300} src="/images/https___fotobello.com.pl_contact_.jpg" />
        </Col>
        <Col sm="6">
          <Formik
            initialValues={{ name: "", email: "", text: "" }}
            onSubmit={onSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <Form id="contact-form" onSubmit={handleSubmit}>
                <FormGroup>
                  <Label>Imię</Label>
                  <Input
                    type="name"
                    name="name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Twój Email</Label>
                  <Input
                    type="email"
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Treść</Label>
                  <Input
                    type="textarea"
                    name="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.text}
                  />
                </FormGroup>
                <button type="submit">Wyślij</button>
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
    </Layout>
  );
};

export default Contact;
