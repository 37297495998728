import React, { useEffect, useState } from "react";
import Layout from "../../components/layout";
import request from "graphql-request";
import { Row } from "reactstrap";
import loadable from "@loadable/component";
const AnimatedCursor = loadable(
  () => import("../../components/AnimatedCursor"),
);

import CardComponent from "./../../components/utilityComponents/CardComponent";
import { isMobile } from "react-device-detect";
import styled from "styled-components";

const Title = styled.h1`
  font-size: 32px;
  color: white;
  text-align: center;
  padding: 20px;
`;

const Page = ({ params }) => {
  const [homepag, setImages] = useState(null);

  useEffect(() => {
    const fetchHomepage = async () => {
      const res = await request(
        "https://api-eu-central-1.graphcms.com/v2/cklr9kcm991dw01z2fb6ngy2s/master",
        `
                 {
                  homepages {
                    categoryGroup{
                      id
                      title
                      slug
                      description{
                            html
                       }
                      image {
                        id
                        url
                      }
                      category{
                        id
                        title
                        titleName
                        description
                         mainCategoryPhoto {
                              id
                              url
                            }
                      }
                    }
                    description{
                      html
                    }
                    id
                    images {
                      id
                      url(transformation: {image: {resize: {height: 1500, width: 1500}}})
                    }
                  }
                } `,
      );

      return res;
    };
    fetchHomepage().then((res) => setImages(res.homepages[0]));
  }, [params?.title]);

  const categoryGroup = homepag?.categoryGroup?.find(
    (e) => e.slug === params.title,
  );

  console.log('kacper')
  console.log(homepag?.categoryGroup)
  console.log(categoryGroup)

  console.log(categoryGroup);

  return (
    <>
      {!isMobile && <AnimatedCursor />}
      <Layout>
        <Title>{categoryGroup?.title ?? ""}</Title>
        <Row className="m-0 d-flex justify-content-start">
          {categoryGroup?.category?.map(
            ({ title, mainCategoryPhoto, titleName, description }, i) => {
              return (
                <CardComponent
                  key={title}
                  title={title}
                  mainPhoto={mainCategoryPhoto.url}
                  slug={titleName}
                  description={description}
                  name="categories"
                />
              );
            },
          )}
        </Row>
      </Layout>
    </>
  );
};

export default Page;
