import React, { useEffect, useState } from "react";
import { Row } from "reactstrap";
import request from "graphql-request";
import ReactLoading from "react-loading";
import { ReactPhotoCollage } from "react-photo-collage";
import styled from "styled-components";
import CardComponent, {
  CardNewComponent,
} from "../utilityComponents/CardComponent";
import Contact from "../../pages/contact";
import { EmblaCarousel } from "../Carousel";

const CategoriesComponent = () => {
  const [categories, setCategories] = useState(null);
  useEffect(() => {
    const fetchProducts = async () => {
      const { categories } = await request(
        "https://api-eu-central-1.graphcms.com/v2/cklr9kcm991dw01z2fb6ngy2s/master",
        `
                  { 
                   categories {
                        id
                        title
                        titleName
                        description
                        photos {
                          id
                          url
                        }
                        number
                        mainCategoryPhoto{
                        id
                        url
                        }
                      }
                } `,
      );
      return categories;
    };
    fetchProducts().then((categories) => setCategories(categories));
  }, []);

  const [images, setImages] = useState([]);

  useEffect(() => {
    const fetchHomepage = async () => {
      const res = await request(
        "https://api-eu-central-1.graphcms.com/v2/cklr9kcm991dw01z2fb6ngy2s/master",
        `
                 {
                  homepages {
                    categoryGroup{
                      id
                      title
                      slug
                      description{
                            html
                       }
                      image {
                        id
                        url
                      }
                      category{
                        id
                        title
                        titleName
                        description
                      }
                    }
                    description{
                      html
                    }
                    id
                    images {
                      id
                      url(transformation: {image: {resize: {height: 1500, width: 1500}}})
                    }
                  }
                } `,
      );

      return res;
    };
    fetchHomepage().then((res) => setImages(res));
  }, []);

  const firstHomepage =
    images.homepages && images.homepages[0] ? images.homepages[0] : null;
  console.log(firstHomepage?.categoryGroup);

  // const setting = {
  //   width: ["1000px"],
  //   height: ["700px"],
  //   layout: [3],
  //   photos: [...(firstHomepage?.images ?? [])].map((p) => ({
  //     source: p.url,
  //   })),
  //   showNumOfRemainingPhotos: true,
  // };

  return (
    <>
      {categories ? (
        <>
          <Center>
            <EmblaCarousel
              images={[...(firstHomepage?.images ?? [])].map((p) => (p.url))}
            />
            <Column>
              <Welcome>Witaj!</Welcome>
              <Description
                dangerouslySetInnerHTML={{
                  __html: firstHomepage?.description?.html,
                }}
              ></Description>
            </Column>
            <Row className="m-0 d-flex justify-content-start">
              {firstHomepage &&
                firstHomepage?.categoryGroup?.map((category, i) => {
                  console.log(category);
                  return (
                    <CardNewComponent
                      key={category.title}
                      title={category.title}
                      mainPhoto={category?.image?.url ?? ""}
                      slug={category.slug}
                      description={category?.description?.html}
                      name="kategoria"
                    />
                  );
                })}
            </Row>
            <Contact isComponent />
          </Center>
        </>
      ) : (
        <div className="h-100 d-flex justify-content-center align-items-center">
          <ReactLoading
            type="bubbles"
            color={"white"}
            height={667 / 2}
            width={375 / 2}
          />
        </div>
      )}
    </>
  );
};

const Column = styled.div`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

const Title = styled.h1`
  font-size: 2rem;
  color: white;
`;

const Welcome = styled.h3`
  font-size: 1.5rem;
  text-align: center;
  color: white;
`;

const Description = styled.div`
  font-size: 1.5rem;
  color: white;
`;

const Center = styled.div`
  margin: auto;
  max-width: 1000px;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  padding: 16px 0;
  display: flex;
  justify-content: center;
`;

export default CategoriesComponent;
