import React, { useEffect, useState } from "react";
import Layout from "../components/layout";
import request from "graphql-request";
import {Card, CardLink, CardSubtitle, CardText, CardTitle, Col, Row} from "reactstrap";
import loadable from "@loadable/component";
const AnimatedCursor = loadable(() => import("../components/AnimatedCursor"));

import { isMobile } from "react-device-detect";
import styled from "styled-components";


const Page = ({ params }) => {
  const [blog, setBlog] = useState(null);

  useEffect(() => {
    const fetchHomepage = async () => {
      const res = await request(
        "https://api-eu-central-1.graphcms.com/v2/cklr9kcm991dw01z2fb6ngy2s/master",
        `
                {
            blogs {
              id
              title
              description
              slug
              content {
                html
              }
            }
          } `,
      );

      return res;
    };
    fetchHomepage().then((res) => setBlog(res));
  }, []);


  return (
    <>
      {!isMobile && <AnimatedCursor />}
      <Layout>
        <Center>
          <Title>Blog</Title>
          <Row className="w-100" style={{ rowGap: 32 }}>
            {blog?.blogs.map((item, i) => (
                <Col sm={12} className='bg-white p-3'>
                  <CardTitle tag="h5">{item?.title ?? ""}</CardTitle>
                  <CardSubtitle tag="h5">{item?.description ?? ""}</CardSubtitle>
                  <CardLink href={`/blog/${item?.slug}`}>
                    przeczytaj całość
                  </CardLink>
                </Col>
            ))}
          </Row>
        </Center>
      </Layout>
    </>
  );
};


const Title = styled.h1`
  font-size: 32px;
  color: white;
  text-align: center;
  padding: 20px;
`;

const Center = styled.div`
  margin:   0  auto 40px;
  max-width: 1000px;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  padding: 16px 0;
  display: flex;
  justify-content: center;
`;

export default Page;
