import React, { useEffect, useState } from "react";
import { request } from "graphql-request";
import Gallery from "react-grid-gallery";

const CategoryComponent = ({ name }) => {
  const [category, setCategory] = useState(null);

  console.log(category?.photos)

  useEffect(() => {
    const fetchProducts = async () => {
      const { categories } = await request(
        "https://api-eu-central-1.graphcms.com/v2/cklr9kcm991dw01z2fb6ngy2s/master",
        `
                  { 
                   categories {
                        id
                        title
                        longDescription
                        descriptionRichText{
                          html
                        }
                        titleName
                        photos {
                          id
                           url(transformation: {image: {resize: {height: 1000, width: 1000}}})
                        }
                      }
                } `,
      );
      return categories.find(({ titleName }) => titleName === name);
    };
    fetchProducts().then((category) => setCategory(category));
  }, []);

  return (
    <div className="d-flex flex-column justify-content-center">
      {category?.descriptionRichText?.html && (
        <h6
          className="longDescription"
          dangerouslySetInnerHTML={{ __html: category.descriptionRichText?.html }}
        />
      )}
      {category && category.photos && (
        <Gallery
          className="gallery-react"
          images={category.photos
            .map(({ url }) => ({
              src: url,
              thumbnail: url,
            }))
            .reverse()}
        />
      )}
    </div>
  );
};

export default CategoryComponent;
