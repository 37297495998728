import React, { useEffect, useState } from "react";
import Layout from "../components/layout";
import request from "graphql-request";
import { Card, CardSubtitle, CardText, CardTitle, Col, Row } from "reactstrap";
import loadable from "@loadable/component";
const AnimatedCursor = loadable(() => import("../components/AnimatedCursor"));
import { isMobile } from "react-device-detect";
import styled from "styled-components";
import Contact from './contact'
const Page = ({ params }) => {
  const [homepag, setImages] = useState(null);

  useEffect(() => {
    const fetchHomepage = async () => {
      const res = await request(
        "https://api-eu-central-1.graphcms.com/v2/cklr9kcm991dw01z2fb6ngy2s/master",
        `
                 {
                  homepages {
                    id
                    priceItemCategory {
                      id
                      title
                      priceItems{
                        id
                        price
                        title
                        description{
                          html
                        }
                      }
                    }
                  }
                } `,
      );

      return res;
    };
    fetchHomepage().then((res) => setImages(res.homepages[0]));
  }, [params.title]);

  return (
    <>
      {!isMobile && <AnimatedCursor />}
      <Layout>
        <Center>
          <Title>Cennik</Title>
          {homepag?.priceItemCategory?.map((props) => {
            return (
              <section
                className="w-100 align-items-center rounded-lg"
                style={{
                  borderWidth:1,
                  borderColor:'white',
                    borderStyle:'solid',
                  padding:32,
                }}
              >
                <Title style={{ padding: 0 }}>{props?.title ?? ""}</Title>
                <Row className="w-100 p-0 m-0" style={{ rowGap: 32 }}>
                  {props?.priceItems?.map((item, i) => (
                    <Col className="w-100" sm={6} lg={6} key={i}>
                      <Card body className="h-100">
                        <CardTitle tag="h5" style={{fontSize:32}}>{item?.title}</CardTitle>
                        <CardSubtitle style={{fontSize:28,textDecoration:'underline',paddingBottom:8}} tag="h5">
                          Cena od {item?.price}
                        </CardSubtitle>
                        <CardTextStyled
                            className='card-text-html-description'
                          dangerouslySetInnerHTML={{
                            __html: item?.description?.html,
                          }}
                        />
                      </Card>
                    </Col>
                  ))}
                </Row>
              </section>
            );
          })}
          <Contact isComponent />
        </Center>
      </Layout>
    </>
  );
};

const CardTextStyled = styled(CardText)`
  p{
    font-size: 20px;
    margin:0;
    padding:0;
  }
`

const Center = styled.div`
  margin: auto;
  max-width: 1000px;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  padding: 16px 0;
  display: flex;
  justify-content: center;
`;

const Title = styled.h1`
  font-size: 32px;
  color: white;
  text-align: center;
  padding: 20px;
`;

export default Page;
