import React, { useEffect, useState } from "react";
import Layout from "../../components/layout";
import request from "graphql-request";
import {
  Card,
  CardLink,
  CardSubtitle,
  CardText,
  CardTitle,
  Col,
  Row,
} from "reactstrap";
import loadable from "@loadable/component";
const AnimatedCursor = loadable(
  () => import("../../components/AnimatedCursor"),
);

import { isMobile } from "react-device-detect";
import styled from "styled-components";

const Page = ({ params }) => {
  const [blog, setBlog] = useState(null);

  useEffect(() => {
    const fetchHomepage = async () => {
      const res = await request(
        "https://api-eu-central-1.graphcms.com/v2/cklr9kcm991dw01z2fb6ngy2s/master",
        `
                {
            blogs {
              id
              title
              description
              slug
              content {
                html
              }
            }
          } `,
      );

      return res;
    };
    fetchHomepage().then((res) => setBlog(res));
  }, [params.slug]);

  const currentItem = blog?.blogs?.find((e) => e.slug === params.slug);
  console.log(currentItem);

  return (
    <>
      {!isMobile && <AnimatedCursor />}
      <Layout>
        <Center>
          <Title>{currentItem?.title}</Title>
          <Section>
            <h2>{currentItem?.description}</h2>
            <p
              dangerouslySetInnerHTML={{ __html: currentItem?.content?.html }}
            />
          </Section>
        </Center>
      </Layout>
    </>
  );
};

const Section = styled.div`
  table{
    width: 100%;
  }
  img {
    width: 100%;
    height: 300px;
    object-fit: contain;
  }
  p {
    font-size: 24px;
  }
  text-align: center;
  background: white;
  padding: 50px;
  width: 100%;
`;

const Title = styled.h1`
  font-size: 32px;
  color: white;
  text-align: center;
  padding: 20px;
`;

const Center = styled.div`
  margin: 0 auto 0;
  max-width: 1000px;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  padding: 32px 0;
  display: flex;
  justify-content: center;
`;

export default Page;
