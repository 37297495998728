import React, { useEffect } from 'react'
import useEmblaCarousel from 'embla-carousel-react'
import Autoplay from 'embla-carousel-autoplay'
import styled, {createGlobalStyle} from "styled-components";

export const EmblaCarousel = ({images}:{images?:string[]}) => {
    const [emblaRef] = useEmblaCarousel({ loop: true }, [Autoplay({delay:3000})])

    return (
      <section>
        <EmbdaCarStyled />
        <div className="embla" ref={emblaRef}>
          <div className="embla__container">
            {images.map((p) => (
              <div className="embla__slide"><Image src={p}/></div>
            ))}
          </div>
        </div>
      </section>
    );
}


const Image = styled.img`
    max-width: 1000px;
    max-height: 700px;
    object-fit: cover;
`

export const EmbdaCarStyled = createGlobalStyle`
    .embla {
        overflow: hidden;
    }
    .embla__container {
        display: flex;
        max-width: 1000px;
    }
    .embla__slide {
        text-align: center;
        flex: 0 0 100%;
        min-width: 0;
    }
`
