import React, {useRef, useState} from "react";
import { Card, CardBody, CardImg, CardText, CardTitle, Col } from "reactstrap";
import ReactLoading from "react-loading";
import styled from "styled-components";


const DescriptionWrapper = styled.div`
  overflow: hidden;
  max-height: ${(props) => (props.isExpanded ? `${props.height}px` : "0")};
  transition: max-height 0.3s ease-in-out;
`;

const CategoryDescription = styled.div`
  font-size: 1.25rem;
  line-height: 1.5rem;
`;


export const CardComponent = ({
  name,
  title,
  mainPhoto,
  slug,
  description,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [contentHeight, setContentHeight] = useState(0);
  const descriptionRef = useRef(null);

  const toggleDescription = () => {
    if (!isExpanded && descriptionRef.current) {
      setContentHeight(descriptionRef.current.scrollHeight);
    }
    setIsExpanded(!isExpanded);
  };


  return (
    <Col
      onMouseEnter={() => {
        toggleDescription();
      }}
      onMouseLeave={() => {
        toggleDescription();
      }}
      md="3"
      className="hover-white"
      style={{ margin: ".5rem 0" }}
    >
      <a style={{ textDecoration: "none" }} href={`/${name}/${slug}`}>
        <Card className="border-0">
          {mainPhoto ? (
            <CardImg
              style={{ height: "200px", objectFit: "contain" }}
              top
              width="100%"
              src={mainPhoto}
              alt="Card image cap"
            />
          ) : (
            <ReactLoading
              type={"bars"}
              color={"white"}
              height={667 / 2}
              width={375 / 2}
            />
          )}
          <CardBody className="category-body">
            <CardTitle className="category-title">{title}</CardTitle>
            <DescriptionWrapper
                className='category-description'
                isExpanded={isExpanded}
                height={contentHeight}
            >
              <CategoryDescription dangerouslySetInnerHTML={{__html:description}} ref={descriptionRef}/>
            </DescriptionWrapper>
          </CardBody>
        </Card>
      </a>
    </Col>
  );
};


export default CardComponent;

export const CardNewComponent = ({
  name,
  title,
  mainPhoto,
  slug,
  description,
}) => {

  const [isExpanded, setIsExpanded] = useState(false);
  const [contentHeight, setContentHeight] = useState(0);
  const descriptionRef = useRef(null);

  const toggleDescription = () => {
    if (!isExpanded && descriptionRef.current) {
      setContentHeight(descriptionRef.current.scrollHeight);
    }
    setIsExpanded(!isExpanded);
  };

  return (
    <Col
        onMouseEnter={() => {
          toggleDescription();
        }}
        onMouseLeave={() => {
          toggleDescription();
        }}
        md="6" className="hover-white" style={{ margin: ".5rem 0" }}>
      <a style={{ textDecoration: "none" }} href={`/${name}/${slug}`}>
        <Card className="border-0">
          {mainPhoto ? (
            <CardImg
              style={{ height: "200px", objectFit: "contain" }}
              top
              width="100%"
              src={mainPhoto}
              alt="Card image cap"
            />
          ) : (
            <ReactLoading
              type={"bars"}
              color={"white"}
              height={667 / 2}
              width={375 / 2}
            />
          )}
          <CardBody className="category-body">
            <CardTitle className="category-title">{title}</CardTitle>
            <DescriptionWrapper
                className='category-description'
                isExpanded={isExpanded}
                height={contentHeight}
            >
              <CategoryDescription dangerouslySetInnerHTML={{__html:description}} ref={descriptionRef}/>
            </DescriptionWrapper>
          </CardBody>
        </Card>
      </a>
    </Col>
  );
};
